import { Sphere, Trail } from "@react-three/drei";
import { forwardRef } from "react";
import { isDesktop } from "react-device-detect";

let options;
if (isDesktop) {
  options = { width: 1.8, length: 4, decay: 1.25, scale: 0.005 };
} else {
  options = { width: 1.2, length: 2, decay: 0.8, scale: 0.0005 };
}
const Particle = forwardRef(function Particle({ color, position }, ref) {
  return (
    <>
      <Trail
        width={options.width} // Width of the line
        color={color} // Color of the line
        length={options.length} // Length of the line
        decay={options.decay} // How fast the line fades away
        local={false} // Wether to use the target's world or local positions
        stride={0} // Min distance between previous and current point
        interval={2} // Number of frames to wait before next calculation
        // target={undefined} // Optional target. This object will produce the trail.
        attenuation={(width) => width} // A function to define the width in each point along it.
      >
        <Sphere ref={ref} position={position} scale={options.scale}>
          <meshStandardMaterial color={color} />
        </Sphere>
      </Trail>
    </>
  );
});

export default Particle;
