import React, { useEffect, useState, useRef } from "react";
import { Vector3 } from "three";
import { useFrame, useThree } from "react-three-fiber";
import { Sparkles } from "@react-three/drei";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import Model from "../Components/Model";
import EnvironmentSettings from "../Components/EnvironmentSettings";
import { Leva } from "leva";
import Particle from "../Components/Particle";
import { isDesktop } from "react-device-detect";
import * as THREE from "three";

export default function PageLayout(props) {
  //url
  let HandModelUrl = isDesktop
    ? "Assets/Models/Seed-hand_v2.glb"
    : "Assets/Models/SeedFractal.glb";
  const HandModel = useRef(null);
  const PointLightRef = useRef(null);
  const ParticleOneRef = useRef(null);
  const ParticleTwoRef = useRef(null);
  const ParticleThreeRef = useRef(null);
  const ParticleFourRef = useRef(null);

  //INFORMATION-STATE
  const [cursor, setCursor] = useState({ x: 0, y: 0 });
  const [cursorMove, setcursorMove] = useState(true);
  const [HandAnimation, setHandAnimation] = useState(null);
  const [ParticleColor, setParticleColor] = useState(0xff0000);
  const { camera } = useThree();
  const page = props.page;
  const setTagline = props.setTagline;
  const tlcamera = gsap.timeline({
    defaults: { overwrite: true },
    scrollTrigger: {
      scroller: "#root",
      trigger: "#pos1",
      start: "end top",
      endTrigger: "#pos4",
      end: "end end",
      scrub: 4,
    },
  });

  const tltext = gsap.timeline({
    defaults: { overwrite: "auto" },
    scrollTrigger: {
      scroller: "#root",
      trigger: "#pos1",
      start: "end top",
      endTrigger: "#pos4",
      end: "end end",
      scrub: 4,
    },
  });

  const data_left = {
    S: [
      "Dive into the core of SEED's ethos, where every initiative is an interplay of innovative ecological solutions and deeply rooted sustainability principles. Witness how our vision translates into tangible actions for a greener future.",
    ],
    A: [
      "HD.png",
      "RC.png",
      "JD.png",
      "NT.png",
      "TBL.png",
      "JK.png",
      "EO.png",
      "BRA.png",
      "SEED.png",
    ],
    F: [
      "Enter the world of Project Swarnadwar, where every step is a testament to sustainable living. This flagship initiative by SEED merges cutting-edge technology with the age-old wisdom of nature, creating a living space that is not only a haven for its inhabitants but also a beacon of ecological harmony.",
      "Project Swarnadwar stands as a shining example of what happens when human ingenuity aligns with nature's rhythm. From energy-efficient homes that whisper tales of the sun and wind, to community spaces that foster growth and unity, every aspect of this project is a chapter in the story of a sustainable future.",
    ],
    D: [
      "Delve into the diverse sectors energized by SEED. From renewable energy to sustainable agriculture, SEED is pioneering innovative sectors that promise a sustainable future. Each sector, a unique ecosystem, thrives under SEED's nurturing vision, creating ripples of positive change across the globe.",
      "<div class='highlight'>SWARNADWAR</div><span class='material-icons'>diversity_3</span><ul><li>Structural Clubs: Collaborative platforms for sustainable architectural innovation.</li><li>Terrarium - Ecosystem: Sustainable ecosystems promoting biodiversity and ecological education.</li><li>Experimental Societies: Real-world testing of innovative environmental, social, and economic theories.</li><li>Community Formation: Building resilient, sustainable communities through educational and resource management initiatives.</li><li>Education Management: Integrating sustainable practices into curriculums to foster eco-conscious minds.</li><li>Research and Development: Pioneering sustainable technologies and methodologies.</li><li>Standard Operating Procedures: Establishing best practices for environmental sustainability.</li></ul>",
      "<div class='highlight'>EDUFARMING</div><span class='material-icons'>eco</span><ul><li>Soil Science: Enhancing soil health and fertility through sustainable methods.</li><li>Biotechnology: Applying scientific principles to improve environmental and agricultural outputs.</li><li>Crop and Marine Production: Increasing yields sustainably in both agriculture and aquaculture.</li><li>Livestock and Agriculture Economics: Ethical livestock raising and economic studies to boost agricultural efficiency.</li><li>Agriculture Engineering: Innovating agricultural productivity through engineering solutions.</li></ul>",
      "<div class='highlight'>EDIFICATION</div><span class='material-icons'>psychology</span><ul><li>MSME and E-Commerce: Empowering small businesses and leveraging online markets.</li><li>Process Design and Social Enterprise: Streamlining processes and creating socially beneficial businesses.</li><li>Health, Education, and Tourism: Promoting wellness, education, and sustainable tourism.</li><li>Community and Media Development: Enhancing community well-being and producing sustainability-focused media.</li></ul>",
      "<div class='highlight'>DEVELOPMENT</div><span class='material-icons'>construction</span><ul><li>Real Estate and Architecture: Building eco-friendly properties and structures.</li><li>Infrastructure and Civil Engineering: Developing sustainable infrastructure through advanced engineering.</li><li>Land Management and Surveying: Optimizing land use and resources sustainably.</li><li>Construction Services: Providing eco-friendly construction and demolition services.</li></ul>",
      "Explore how SEED is revolutionizing traditional industries with cutting-edge technology and sustainable practices. In sectors ranging from clean technology to eco-friendly real estate, SEED is redefining the norms, setting new benchmarks for environmentally conscious operations and community-centric development. <div class='logo-for-sector'></div>",
    ],
    C: ["PB-0.png", "PB-1.png", "PB-2.png"],
    G: ["Suraj.png", "Darshan.png", "Janet.png", "Careers.png", "Contact.png"],
  };

  const data_right = {
    S: [
      "In a world that's both sturdy and square,<br><br>",
      "The cube stands with Earth in its care.<br><br>",
      "With edges so neat, And faces that meet,<br><br>",
      "It's a symbol of grounding we share",
    ],
    A: [
      "In a world where resource limits are respected and prosperity is redefined, SEED's approach to a balanced economy merges Herman Daly's insights with sustainable practices. It's an economy where growth is measured not just in numbers, but in harmony and well-being.",
      "Inspired by Rachel Carson's vision, SEED fosters ecological harmony, intertwining human progress with the rhythms of nature. It's a commitment to a world where our footsteps leave imprints of growth, not scars.",
      "Drawing inspiration from Jane Goodall's work, SEED champions social unity. It's a space where diverse voices converge, building communities that are strong in their inclusivity and rich in their collective human spirit.",
      "Channeling the ingenuity of Nikola Tesla, SEED is at the forefront of sustainable energy. It's a journey towards harnessing the earth’s renewable gifts, powering our lives in harmony with the planet.",
      "In the spirit of Tim Berners-Lee's innovations, SEED integrates cutting-edge technology for societal betterment. It's about leveraging technological advancements to pave the path towards a sustainable, interconnected world.",
      "SEED's holistic education, resonant with Jiddu Krishnamurthy's philosophy, nurtures the mind, body, and spirit. It's an education system that transcends traditional boundaries, fostering lifelong learning and personal growth.",
      "Inspired by Elinor Ostrom's principles, SEED's responsive governance model is rooted in community participation and transparent decision-making. It's governance that listens, adapts, and evolves with the community’s heartbeat.",
      "In the legacy of Dr. B R Ambedkar, SEED cultivates an environment where cultural diversity flourishes and health is a communal treasure. It’s a celebration of diverse cultural expressions and a commitment to holistic well-being.",
      "Creating a Sustainable Utopia: We envision a world where mankind and nature thrive in Harmony, at SEED, we’re committed to nurturing a terrarium of integrated ecosystems, leading humanity towards a sustainable future.",
    ],
    B: [
      "Swarnadwar epitomizes the essence of a sustainable future. It stands as a beacon where innovative technology and timeless ecological wisdom converge, creating a harmonious and thriving community that respects nature's delicate balance.",
      "Edufarming represents a revolutionary blend of education and agriculture. It's an innovative approach where learning transcends the classroom, nurturing minds in tandem with nurturing the earth, fostering a deep-rooted understanding of sustainable living and environmental stewardship.",
      "Edification in SEED’s vision is about constructing more than just physical structures; it's about building foundations of knowledge and empowering communities. It embodies the commitment to educational excellence, intellectual growth, and the holistic development of individuals.",
      "Development at SEED goes beyond mere construction. It's about creating spaces that are sustainable, inclusive, and forward-thinking. Each development project is a step towards shaping a future where communities live in harmony with their environment, ensuring prosperity and well-being for generations.",
      "&nbsp;&nbsp;&nbsp;CIN: U74999KA2019PTC122909 <br><br> &nbsp;&nbsp;&nbsp;MSME: UDYAM-KR-03-0251347 <br><br> &nbsp;&nbsp;&nbsp;GSTIN: 29ABBCS8310C1ZK <br><br> &nbsp;&nbsp;&nbsp;DIPP89905",
    ],
    C: [
      "HOLOCENE",
      "<div class='year'>YEAR</div> 1950",
      "<div class='year'>YEAR</div> 1960",
      "<div class='year'>YEAR</div> 1970",
      "<div class='year'>YEAR</div> 1980",
      "<div class='year'>YEAR</div> 1990",
      "<div class='year'>YEAR</div> 2000",
      "<div class='year'>YEAR</div> 2010",
      "<div class='year'>YEAR</div> 2020",
      "<div class='year'>YEAR</div> 2023 <br><br> for more info: <a class='link' href='https://globaia.org/boundaries' target='_blank'>Globaia.org</a>",
    ],
    D: [
      "FF-1.png",
      "FF-2.png",
      "FF-3.png",
      "FF-4.png",
      "FF-5.png",
      "FF-6.png",
      "FF-7.png",
      "FF-8.png",
    ],
    E: [
      "<div class='highlight'>PROJECT EXODUS</div>",
      "Immerse yourself in Project EXODUS, where music and technology converge in a symphony of innovation. This is a world where every note opens a new horizon, and every event is a canvas for collective creativity",
      "Project EXODUS isn't just a platform; it's a portal to a universe where artists, venues, and enthusiasts are woven into a vibrant tapestry of connections. Discover the Octave Rating System, a harmonious blend of technology and artistry, ensuring every interaction resonates with precision and passion",
      "Step into this dynamic realm, and let the parallax of possibilities unfold. Feel the pulse of live events, engage in immersive virtual experiences, and become part of a movement that's redefining the rhythm of connection and celebration",
      "Click on the Project EXODUS logo, your gateway to a new dimension where every beat, every note, and every gathering is a step towards harmonizing the future. Join the symphony, be the harmony",
      "<div class='highlight'>Project ECOLITH</div>",
      "Where innovation meets sustainability, sculpting the future of commercial infrastructure",
      "Elevate your business with Project ECOLITH, a harmonious blend of cutting-edge design and environmental stewardship",
      "Experience the pinnacle of eco-friendly development at Project ECOLITH, where every detail is a testament to progress and sustainability",
      "Explore Project ECOLITH, a pioneering SEED initiative that transforms commercial spaces with groundbreaking concepts like Agri Voltaic and Biomimicry",
      "Situated in a prime location, ECOLITH blends advanced green technologies, such as solar power and sustainable waste management, with innovative design principles inspired by nature",
      "This eco-friendly hub is not just a business center, but a beacon of environmental stewardship and cutting-edge ideas. Join us in shaping a future where architecture harmonizes with the environment, creating a state-of-the-art project that sets new standards for sustainability and innovation",
    ],
    G: [
      "<div class='highlight'>VISIONARY</div>Embracing a clear purpose-based vision to serve people in meaningful ways is the white hot center that drives our business forward; far beyond moneymaking",
      "<div class='highlight'>ENGINEER</div>Our values lead our team to move not only in the same direction, but also in the right direction; continously improving, expanding our contributions to our customers through experiences they love and can't live without",
      "<div class='highlight'>OPERATIONS TALENT DEVELOPMENT</div>Let us follow the crowded path but instead recognize the unseen trends and create value in a category uniquely our own. It's in the transformation of known concepts into something remarkable that we find radical success, much like the innovation of Swatch. This is where our future lies",
      "At SEED, we're not just building a company; we're nurturing a movement. Dive into an ecosystem where your ideas can flourish, where your passion for the planet is shared, and where your contributions directly shape a sustainable future. We're looking for trailblazers, thinkers, and visionaries ready to join us on this transformative journey.<br><br>Talent wins games, but teamwork and collective intelligence win championships. At SEED, you'll be part of a diverse team united by a common goal: engineering sustainability. Whether you're a scientist, a strategist, or a storyteller, your role is crucial. Together, we'll turn challenges into opportunities and dreams into realities.<br><br>Join the movement: careers@seedpvtltd.com",
      "Message us at <a class='link' href='https://wa.me/message/PZHWIWS6PT6WL1' target='__blank'><u>WHATSAPP</u></a><br><div class='whatsapp-code'></div><a class='link' href='https://www.sensepvtltd.com' target='__blank'><div class='powered'>Powered by SEΠSE<div class='sense-logo'></div></div>",
    ],
  };

  const data_center = {
    S1: ["HARVESTING TOMORROW'S SUSTAINABILITY"],
    S2: ["&nbsp;TODAY"],
    A1: ["SUSTAINABILITY BEGINS HERE"],
    A2: [
      "&nbsp;- NURTURING THE SEEDs OF TOMORROW",
      "&nbsp;- ENVISIONING A NEW ECONOMIC PARADIGM",
      "&nbsp;- HARMONIZING HUMANITY WITH NATURE",
      "&nbsp;- UNITING FOR A COLLECTIVE PURPOSE",
      "&nbsp;- POWERING THE FUTURE SUSTAINABLY",
      "&nbsp;- TECHNOLOGY AS A FORCE FOR GOOD",
      "&nbsp;- EDUCATING THE WHOLE BEING",
      "&nbsp;- GOVERNING WITH COLLECTIVE WISDOM",
      "&nbsp;- CULTIVATING RICH CULTURES AND HEALTHY LIVES",
      "&nbsp;- SEED's VISION",
    ],

    B1: [
      "/&nbsp;THE GOLDEN DOOR",
      "/&nbsp;CULTIVATING MINDS, NURTURING EARTH",
      "/&nbsp;BUILDING KNOWLEDGE, ELEVATING POTENTIAL",
      "/&nbsp;SHAPING SUSTAINABLE FUTURES",
    ],
    B2: [
      "SWARNADWAR&nbsp;",
      "EDUFARMING&nbsp;",
      "EDIFICATION&nbsp;",
      "DEVELOPMENT&nbsp;",
    ],
    C1: ["PLANETARY"],
    C2: ["&nbsp;BOUNDARIES !"],
    D1: ["SEED'S SECTORS OF OPERATIONS :"],
    D2: ["&nbsp;CULTIVATING DIVERSITY, HARVESTING INNOVATION"],
    E1: ["SEED's"],
    E2: ["&nbsp;ONGOING ENDEAVOURS"],
    F1: ["PROJECT SWARNADWAR"],
    F2: ["&nbsp;- HARMONIZING HUMANITY WITH NATURE"],
    G1: ["MEET THE MINDS"],
    G2: [
      "&nbsp;BEHIND THE INNOVATION",
      "&nbsp;SURAJ SUVARNA",
      "&nbsp;DARSHAN DWARKANATH",
      "&nbsp;JANET MASCARENHAS",
      "&nbsp;AND JOIN THE JOURNEY",
      "&nbsp;AND KNOW MORE",
    ],
  };

  const data_tagline = {
    S: ["Where Innovation Meets Tradition"],
    D: [
      '"            Empowering <br> &nbsp; Sustainable Sectors"',
      '"            Revolutionizing Through <br> &nbsp; Innovation"',
      '"            Where Every Sector <br> &nbsp; Blooms With Possibility"',
    ],
  };

  const sparklesSettings = isDesktop
    ? { position: { x: -5, y: 2, z: -37 }, scale: 10, size: 5.4 }
    : { position: { x: -7, y: 0.6, z: -35 }, scale: 2, size: 2.4 };

  useGSAP(() => {
    gsap.to(".navigation", {
      autoAlpha: 0,
      scrollTrigger: {
        scroller: "#root",
        trigger: "#pos1",
        start: "top top",
        end: "+=10",
        scrub: 1,
      },
    });

    switch (page) {
      case "S":
        if (isDesktop) {
        } else {
          tlcamera.to(camera.position, { x: -2, y: -10, z: -21.4 });
        }
        document.querySelector(".display-board-left").innerHTML =
          "<div class='pageS-board-left hidden'></div>";
        document.querySelector(".display-board-right").innerHTML =
          "<div class='pageS-board-right'><span class='right-stagger hidden' id='rs-0'></span><span class='right-stagger hidden' id='rs-1'></span><span class='right-stagger hidden' id='rs-2'></span><span class='right-stagger hidden' id='rs-3'></span></div>";
        document.querySelector(".headline-LHS").innerHTML =
          "<div class='pageS-headlineLHS hidden'>" +
          data_center.S1[0] +
          "</div>";
        document.querySelector(".headline-RHS").innerHTML =
          "<div class='pageS-headlineRHS hidden'>" +
          data_center.S2[0] +
          "</div>";
        document.querySelector(".tagline").innerHTML =
          "<div class='pageS-tagline hidden'>" + data_tagline.S[0] + "</div>";

        tltext.to(".pageS-headlineLHS", { autoAlpha: 1 });
        tltext.set(".pageS-board-left", { innerHTML: data_left.S[0] });
        tltext.to(
          ".pageS-board-left",
          {
            autoAlpha: 1,
            filter: "blur(0px)",
          },
          "<"
        );
        tltext.to(
          ".pageS-board-left",
          {
            autoAlpha: 0,
            filter: "blur(4px)",
          },
          ">"
        );
        for (let i = 0; i < 4; i++) {
          tltext.set(
            "#rs-" + i,
            {
              innerHTML: data_right.S[i],
            },
            "<"
          );
        }
        tltext.to(
          ".right-stagger",
          {
            autoAlpha: 1,
            stagger: {
              each: 0.2,
              from: "top",
              grid: "auto",
            },
          },
          ">"
        );
        tltext.fromTo(
          ".pageS-tagline",
          { autoAlpha: 0 },
          { autoAlpha: 1 },
          ">"
        );
        tltext.to(".pageS-headlineRHS", { autoAlpha: 1 }, "<");

        break;
      case "A":
        if (isDesktop) {
          tlcamera.to(camera.position, { x: -2.01, y: -1.8, z: -21.4 });
          tlcamera.to(camera.rotation, { x: 0.11, y: 0.38, z: 0.0 }, "<");
        } else {
          tlcamera.to(camera.position, { x: -2, y: -10, z: -21.4 });
        }

        document.querySelector(".display-board-left").innerHTML =
          "<div class='pageC-board-left'><div class='pb pb-0 hidden'></div><div class='pb pb-1 hidden'></div><div class='pb pb-2 hidden'></div><div class='pb pb-3 hidden'></div><div class='pb pb-4 hidden'></div><div class='pb pb-5 hidden'></div><div class='pb pb-6 hidden'></div><div class='pb pb-7 hidden'></div><div class='pb pb-8 hidden'></div><div class='pb pb-9 hidden'></div><div class='pb pb-text hidden'>YEAR</div></div>";
        document.querySelector(".display-board-right").innerHTML =
          "<div class='pageC-board-right hidden'></div>";
        document.querySelector(".headline-LHS").innerHTML =
          "<div class='pageC-headlineLHS hidden'>" +
          data_center.C1[0] +
          "</div>";
        document.querySelector(".headline-RHS").innerHTML =
          "<div class='pageC-headlineRHS hidden'>" +
          data_center.C2[0] +
          "</div>";
        document.querySelector(".tagline").innerHTML =
          "<div class='pageC-tagline hidden'></div>";

        tltext.to(".pageC-headlineLHS", { autoAlpha: 1 });
        tltext.to(".pageC-headlineRHS", { autoAlpha: 1 }, ">");

        for (let i = 0; i < 10; i++) {
          tltext.set(".pageC-board-right", {
            innerHTML: data_right.C[i],
          });
          tltext.to(".pageC-board-right", { autoAlpha: 1 }, ">");
          tltext.fromTo(
            ".pb-" + i,
            { autoAlpha: 0, clipPath: "circle(0%)" },
            { autoAlpha: 1, clipPath: "circle(100%)" },
            "<"
          );
          if (i < 9) {
            tltext.to(".pageC-board-right", { autoAlpha: 0 }, ">");
          }
        }
        break;

      case "B":
        if (isDesktop) {
          tlcamera.to(camera.position, { x: -1, y: -0.9, z: -26.0 });
          tlcamera.to(camera.rotation, { x: 0.11, y: 0.82, z: 0.0 }, "<");
        } else {
          tlcamera.to(camera.position, { x: -2, y: -10, z: -21.4 });
        }

        document.querySelector(".display-board-left").innerHTML =
          "<div class='pageB-board-left'><div class='sl sl-0 hidden'></div><div class='sl sl-1 hidden'></div><div class='sl sl-2 hidden'></div><div class='sl sl-3 hidden'></div></div>";
        document.querySelector(".display-board-right").innerHTML =
          "<div class='pageB-board-right hidden'></div>";
        document.querySelector(".headline-LHS").innerHTML =
          "<div class='pageB-headlineLHS hidden'></div>";
        document.querySelector(".headline-RHS").innerHTML =
          "<div class='pageB-headlineRHS hidden'></div>";
        document.querySelector(".tagline").innerHTML =
          "<div class='pageB-tagline hidden'></div>";

        let initialwidth =
          document.querySelector(".pageB-board-right").offsetWidth;
        let newwidth = initialwidth + initialwidth / 10;

        for (let i = 0; i < 5; i++) {
          if (i === 4) {
            tltext.set(".pageB-board-right", {
              innerHTML: "" + data_right.B[i],
            });
            tltext.to(".pageB-board-right", { autoAlpha: 1 }, "<");
          } else {
            tltext.set(".pageB-headlineLHS", {
              innerHTML: "" + data_center.B2[i],
            });
            tltext.set(".pageB-headlineRHS", {
              innerHTML: "" + data_center.B1[i],
            });
            tltext.set(".pageB-board-right", {
              innerHTML: "" + data_right.B[i],
            });
            tltext.to(".pageB-headlineLHS", { autoAlpha: 1 }, ">");
            tltext.to(".pageB-headlineRHS", { autoAlpha: 1 }, ">");
            tltext.to(".sl-" + i, { autoAlpha: 1 }, "<");
            tltext.to(".pageB-board-right", { autoAlpha: 1 }, "<");
            tltext.to(".pageB-headlineLHS", { autoAlpha: 0 }, ">");
            tltext.to(".pageB-headlineRHS", { autoAlpha: 0 }, "<");
            tltext.to(
              ".pageB-board-right",
              { autoAlpha: 0, filter: "blur(4px)", width: newwidth + "px" },
              "<"
            );
            tltext.set(
              ".pageB-board-right",
              {
                width: initialwidth + "px",
                filter: "blur(0px)",
              },
              ">"
            );
          }
        }
        break;
      case "C":
        if (isDesktop) {
          tlcamera.to(camera.position, { x: -3.7, y: -1.65, z: -21.0 });
          tlcamera.to(camera.rotation, { x: 0.13, y: 0.25, z: 0.0 }, "<");
        } else {
          tlcamera.to(camera.position, { x: -2, y: -10, z: -21.4 });
        }

        document.querySelector(".display-board-left").innerHTML =
          "<div class='pageA-board-left hidden'></div>";
        document.querySelector(".display-board-right").innerHTML =
          "<div class='pageA-board-right hidden'></div>";
        document.querySelector(".headline-LHS").innerHTML =
          "<div class='pageA-headlineLHS hidden'>" +
          data_center.A1[0] +
          "</div>";
        document.querySelector(".headline-RHS").innerHTML =
          "<div class='pageA-headlineRHS hidden'>" +
          data_center.A2[0] +
          "</div>";
        document.querySelector(".tagline").innerHTML =
          "<div class='pageA-tagline hidden'></div>";

        tltext.to(".pageA-headlineLHS", { autoAlpha: 1 });
        tltext.to(".pageA-headlineRHS", { autoAlpha: 1 }, ">");

        for (let i = 0; i < 9; i++) {
          tltext.to(".pageA-headlineRHS", { autoAlpha: 0 }, ">");
          tltext.set(
            ".pageA-board-left",
            {
              backgroundImage: "url(Assets/Images/" + data_left.A[i],
              backgroundSize: "contain",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              innerHTML: "",
            },
            ">"
          );
          tltext.set(
            ".pageA-board-right",
            {
              innerHTML: "" + data_right.A[i],
            },
            "<"
          );

          tltext.set(
            ".pageA-headlineRHS",
            {
              innerHTML: "" + data_center.A2[i + 1],
            },
            "<"
          );
          tltext.fromTo(
            ".pageA-board-left",
            {
              autoAlpha: 0,
              clipPath: "circle(0%)",
            },
            {
              autoAlpha: 1,
              clipPath: "circle(100%)",
            },
            "<"
          );

          tltext.fromTo(
            ".pageA-board-right",
            { autoAlpha: 0, filter: "blur(4px)" },
            { autoAlpha: 1, filter: "blur(0px)" },
            "<"
          );
          tltext.to(".pageA-headlineRHS", { autoAlpha: 1 }, "<");

          if (i !== 8) {
            tltext.to(".pageA-board-left", { autoAlpha: 0 }, ">");

            tltext.fromTo(
              ".pageA-board-right",
              { autoAlpha: 1, filter: "blur(0px)" },
              { autoAlpha: 0, filter: "blur(4px)" },
              "<"
            );
            tltext.to(".pageA-headlineRHS", { autoAlpha: 0 }, "<");
          }
        }
        break;

      case "D":
        if (isDesktop) {
          tlcamera.to(camera.position, { x: -2.01, y: -1.8, z: -21.4 });
          tlcamera.to(camera.rotation, { x: 0.11, y: 0.38, z: 0.0 }, "<");
        } else {
          tlcamera.to(camera.position, { x: -2, y: -10, z: -21.4 });
        }

        document.querySelector(".display-board-left").innerHTML =
          "<div class='pageD-board-left hidden'></div>";
        document.querySelector(".display-board-right").innerHTML =
          "<div class='pageD-board-right'><div class='ff ff-0 hidden'></div><div class='ff ff-1 hidden'></div><div class='ff ff-2 hidden'></div><div class='ff ff-3 hidden'></div><div class='ff ff-4 hidden'></div><div class='ff ff-5 hidden'></div><div class='ff ff-6 hidden'></div><div class='ff ff-7 hidden'></div></div>";
        document.querySelector(".headline-LHS").innerHTML =
          "<div class='pageD-headlineLHS hidden'></div>";
        document.querySelector(".headline-RHS").innerHTML =
          "<div class='pageD-headlineRHS hidden'></div>";
        document.querySelector(".tagline").innerHTML =
          "<div class='pageD-tagline hidden'></div>";

        tltext.set(".pageD-headlineLHS", { innerHTML: data_center.D1[0] }, "<");
        tltext.set(".pageD-headlineRHS", { innerHTML: data_center.D2[0] }, "<");
        tltext.to(".pageD-headlineLHS", { autoAlpha: 1 }, ">");
        tltext.fromTo(
          ".pageD-headlineRHS",
          { autoAlpha: 0, x: 36 },
          { autoAlpha: 1, x: 0 },
          ">"
        );
        tltext.set(".pageD-board-left", { innerHTML: data_left.D[0] });
        tltext.to(".pageD-board-left", { autoAlpha: 1 }, "<");
        tltext.to(
          ".pageD-board-left",
          {
            autoAlpha: 0,
            filter: "blur(4px)",
          },
          ">"
        );
        tltext.set(".pageD-board-left", { innerHTML: data_left.D[1] });
        tltext.to(".ff-0", { autoAlpha: 1 }, ">");
        tltext.to(
          ".pageD-board-left",
          {
            autoAlpha: 1,
            filter: "blur(0px)",
          },
          "<"
        );
        tltext.to(
          ".pageD-board-left",
          {
            autoAlpha: 0,
            filter: "blur(4px)",
          },
          ">"
        );
        tltext.set(".pageD-tagline", { innerHTML: data_tagline.D[0] }, "<");
        tltext.to(".ff-1", { autoAlpha: 1 }, ">");
        tltext.to(".pageD-tagline", { autoAlpha: 1 }, "<");
        tltext.to(".pageD-tagline", { autoAlpha: 0, filter: "blur(4px)" }, ">");
        tltext.set(".pageD-board-left", { innerHTML: data_left.D[2] });
        tltext.to(".ff-2", { autoAlpha: 1 }, "<");
        tltext.to(
          ".pageD-board-left",
          {
            autoAlpha: 1,
            filter: "blur(0px)",
          },
          "<"
        );
        tltext.to(
          ".pageD-board-left",
          {
            autoAlpha: 0,
            filter: "blur(4px)",
          },
          ">"
        );
        tltext.set(".pageD-tagline", { innerHTML: data_tagline.D[1] }, "<");
        tltext.to(".ff-3", { autoAlpha: 1 }, ">");
        tltext.to(".pageD-tagline", { autoAlpha: 1, filter: "blur(0px)" }, "<");
        tltext.to(".pageD-tagline", { autoAlpha: 0, filter: "blur(4px)" }, ">");
        tltext.set(".pageD-board-left", { innerHTML: data_left.D[3] });
        tltext.to(".ff-4", { autoAlpha: 1 }, "<");
        tltext.to(
          ".pageD-board-left",
          {
            autoAlpha: 1,
            filter: "blur(0px)",
          },
          "<"
        );
        tltext.to(
          ".pageD-board-left",
          {
            autoAlpha: 0,
            filter: "blur(4px)",
          },
          ">"
        );
        tltext.set(".pageD-tagline", { innerHTML: data_tagline.D[2] }, "<");
        tltext.to(".ff-5", { autoAlpha: 1 }, ">");
        tltext.to(".ff-6", { autoAlpha: 1 }, "<");
        tltext.to(".pageD-tagline", { autoAlpha: 1, filter: "blur(0px)" }, "<");
        tltext.to(".pageD-tagline", { autoAlpha: 0, filter: "blur(4px)" }, ">");

        tltext.set(".pageD-board-left", { innerHTML: data_left.D[4] });
        tltext.to(".ff-7", { autoAlpha: 1 }, "<");

        tltext.to(
          ".pageD-board-left",
          {
            autoAlpha: 1,
            filter: "blur(0px)",
          },
          "<"
        );
        tltext.to(
          ".pageD-board-left",
          {
            autoAlpha: 0,
            filter: "blur(4px)",
          },
          ">"
        );
        tltext.set(".pageD-board-left", { innerHTML: data_left.D[5] });
        tltext.to(
          ".pageD-board-left",
          {
            autoAlpha: 1,
            filter: "blur(0px)",
          },
          "<"
        );
        break;
      case "E":
        if (isDesktop) {
          tlcamera.to(camera.position, { x: -7.0, y: -0.6, z: -20.0 });
          tlcamera.to(camera.rotation, { x: 0.11, y: -0.05, z: 0.02 }, "<");
        } else {
          tlcamera.to(camera.position, { x: -2, y: -10, z: -21.4 });
        }

        document.querySelector(".display-board-left").innerHTML =
          "<div class='pageE-board-left'><div class='pl-exodus'><div class='pl pl-0 hidden' onclick='window.open(\"https://www.werexodus.com\", \"mywindow\");'></div><div class='pl pl-1 hidden' onclick='window.open(\"https://www.werexodus.com\", \"mywindow\");'></div><div class='pl pl-2 hidden' onclick='window.open(\"https://www.werexodus.com\", \"mywindow\");'></div><div class='pl pl-3 hidden' onclick='window.open(\"https://www.werexodus.com\", \"mywindow\");'></div><div class='pl pl-4 hidden' onclick='window.open(\"https://www.werexodus.com\", \"mywindow\");'></div></div><div class='pl-ecolith'><div class='pl pl-5 hidden'></div><div class='pl pl-6 hidden'></div><div class='pl pl-7 hidden'></div><div class='pl pl-8 hidden'></div><div class='pl pl-9 hidden'></div><div class='pl pl-10 hidden'></div><div class='pl pl-11 hidden'></div></div></div>";
        document.querySelector(".display-board-right").innerHTML =
          "<div class='pageE-board-right hidden'></div>";
        document.querySelector(".headline-LHS").innerHTML =
          "<div class='pageE-headlineLHS hidden'></div>";
        document.querySelector(".headline-RHS").innerHTML =
          "<div class='pageE-headlineRHS hidden'></div>";
        document.querySelector(".tagline").innerHTML =
          "<div class='pageE-tagline hidden'></div>";

        tltext.set(".pageE-headlineLHS", { innerHTML: data_center.E1[0] }, "<");
        tltext.set(".pageE-headlineRHS", { innerHTML: data_center.E2[0] }, "<");
        tltext.to(".pageE-headlineLHS", { autoAlpha: 1 }, ">");
        tltext.fromTo(
          ".pageE-headlineRHS",
          { autoAlpha: 0, x: 36 },
          { autoAlpha: 1, x: 0 },
          ">"
        );

        let initialwidthE =
          document.querySelector(".pageE-board-right").offsetWidth;
        let newwidthE = initialwidthE + initialwidthE / 10;

        for (let i = 0; i < 12; i++) {
          tltext.set(".pageE-board-right", {
            innerHTML: "" + data_right.E[i],
          });
          tltext.to(".pl-" + i, { autoAlpha: 1 }, "<");
          tltext.to(".pageE-board-right", { autoAlpha: 1 }, "<");
          tltext.to(
            ".pageE-board-right",
            { autoAlpha: 0, filter: "blur(4px)", width: newwidthE + "px" },
            ">"
          );
          tltext.set(
            ".pageE-board-right",
            {
              width: initialwidthE + "px",
              filter: "blur(0px)",
            },
            ">"
          );
        }
        break;

      case "F":
        if (isDesktop) {
          tlcamera.to(camera.position, { x: 2.64, y: -1.2, z: -24.5 });
          tlcamera.to(camera.rotation, { x: 0.11, y: 0.98, z: 0.0 }, "<");
        } else {
          tlcamera.to(camera.position, { x: -2, y: -10, z: -21.4 });
        }

        document.querySelector(".display-board-left").innerHTML =
          "<div class='pageF-board-left hidden'></div>";
        document.querySelector(".display-board-right").innerHTML =
          "<div class='pageF-board-right'><div class='sl sl-0 hidden'></div></div>";
        document.querySelector(".headline-LHS").innerHTML =
          "<div class='pageF-headlineLHS hidden'></div>";
        document.querySelector(".headline-RHS").innerHTML =
          "<div class='pageF-headlineRHS hidden'></div>";
        document.querySelector(".tagline").innerHTML =
          "<div class='pageF-tagline hidden'></div>";

        let initialwidthF =
          document.querySelector(".pageF-board-left").offsetWidth;
        let newwidthF = initialwidthF + initialwidthF / 10;

        tltext.set(".pageF-headlineLHS", { innerHTML: data_center.F1[0] }, "<");
        tltext.set(".pageF-headlineRHS", { innerHTML: data_center.F2[0] }, "<");

        tltext.to(".pageF-headlineLHS", { autoAlpha: 1 }, ">");
        tltext.to(".sl-0", { autoAlpha: 1 }, "<");
        tltext.to(".pageF-board-right", { autoAlpha: 1 }, "<");
        tltext.fromTo(
          ".pageF-headlineRHS",
          { autoAlpha: 0, x: 36 },
          { autoAlpha: 1, x: 0 },
          ">"
        );
        tltext.set(".pageF-board-left", { innerHTML: data_left.F[0] }, ">");
        tltext.to(".pageF-board-left", { autoAlpha: 1 }, ">");
        tltext.to(
          ".pageF-board-left",
          { autoAlpha: 0, filter: "blur(4px)", width: newwidthF + "px" },
          ">"
        );
        tltext.set(
          ".pageF-board-left",
          {
            innerHTML: data_left.F[1],
            filter: "blur(0px)",
            width: initialwidthF + "px",
          },
          ">"
        );
        tltext.to(".pageF-board-left", { autoAlpha: 1 }, ">");
        break;

      case "G":
        if (isDesktop) {
        } else {
          tlcamera.to(camera.position, { x: -2, y: -10, z: -21.4 });
        }

        document.querySelector(".display-board-left").innerHTML =
          "<div class='pageG-board-left hidden'></div>";
        document.querySelector(".display-board-right").innerHTML =
          "<div class='pageG-board-right'></div>";
        document.querySelector(".headline-LHS").innerHTML =
          "<div class='pageG-headlineLHS hidden'></div>";
        document.querySelector(".headline-RHS").innerHTML =
          "<div class='pageG-headlineRHS hidden'></div>";
        document.querySelector(".tagline").innerHTML =
          "<div class='pageG-tagline hidden'></div>";

        tltext.set(".pageG-headlineLHS", { innerHTML: data_center.G1[0] }, "<");
        tltext.set(".pageG-headlineRHS", { innerHTML: data_center.G2[0] }, "<");
        tltext.to(".pageG-headlineLHS", { autoAlpha: 1 }, ">");
        tltext.fromTo(
          ".pageG-headlineRHS",
          { autoAlpha: 0, x: 36 },
          { autoAlpha: 1, x: 0 },
          ">"
        );
        tltext.to(".pageG-headlineRHS", { autoAlpha: 0 }, ">");
        for (let i = 0; i < 5; i++) {
          tltext.set(
            ".pageG-board-left",
            {
              backgroundImage: "url(Assets/Images/" + data_left.G[i],
              backgroundSize: "contain",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              innerHTML: "",
            },
            ">"
          );
          tltext.set(
            ".pageG-headlineRHS",
            {
              innerHTML: "" + data_center.G2[i + 1],
            },
            "<"
          );
          tltext.to(".pageG-headlineRHS", { autoAlpha: 1 }, "<");
          tltext.set(
            ".pageG-board-right",
            {
              innerHTML: "" + data_right.G[i],
            },
            "<"
          );

          tltext.fromTo(
            ".pageG-board-left",
            {
              autoAlpha: 0,
              clipPath: "circle(0%)",
            },
            {
              autoAlpha: 1,
              clipPath: "circle(100%)",
            },
            "<"
          );

          tltext.fromTo(
            ".pageG-board-right",
            { autoAlpha: 0, filter: "blur(4px)" },
            { autoAlpha: 1, filter: "blur(0px)" },
            "<"
          );
          if (i !== 4) {
            tltext.to(".pageG-board-left", { autoAlpha: 0 }, ">");

            tltext.fromTo(
              ".pageG-board-right",
              { autoAlpha: 1, filter: "blur(0px)" },
              { autoAlpha: 0, filter: "blur(4px)" },
              "<"
            );
            tltext.to(".pageG-headlineRHS", { autoAlpha: 0 }, "<");
          }
        }

        break;
      default:
        break;
    }
  }, [page]);

  useEffect(() => {
    const loaded = () => {
      document.querySelector(".right").style.opacity = 1;
      gsap.to(".logo-main", { opacity: 1, duration: 4 });
      gsap.fromTo(
        ".navigation",
        { autoAlpha: 0 },
        {
          autoAlpha: 1,
          duration: 4,
          onComplete: () => {
            gsap.to(["#button-A", "#button-B", "#button-D", "#button-F"], {
              rotateX: "0deg",
              rotateY: "0deg",
              duration: 1,
            });
            gsap.to("#button-E", {
              rotateX: "0deg",
              rotateY: "0deg",
              duration: 1,
              delay: 1.2,
              onComplete: () => {
                setTimeout(() => {
                  document
                    .querySelectorAll(".cube-visible")
                    .forEach((value) => {
                      value.classList.remove("cube-initial");
                    });
                }, 1000);
                gsap.to([".nav-title-a", ".nav-title-b"], {
                  opacity: 1,
                  duration: 1,
                  delay: 1,
                  onComplete: () => {
                    gsap.to(".scroll-down", {
                      opacity: 1,
                      duration: 1,
                      onComplete: () => {
                        document.getElementById("root").style.overflow = "auto";
                        let buttons =
                          document.querySelectorAll(".cube-visible");
                        for (let i = 0; i < buttons.length; i++) {
                          setTimeout(() => {
                            buttons[i].classList.add("cube-glow");
                          }, 1000 + i * 1000);
                          setTimeout(() => {
                            buttons[i].classList.remove("cube-glow");
                          }, 2000 + i * 1000);
                        }
                      },
                    });
                  },
                });
              },
            });
          },
        }
      );
      let cursorUpdated = {
        x: 0,
        y: 0,
      };
      window.addEventListener("mousemove", (event) => {
        cursorUpdated.x = event.clientX / window.innerWidth - 0.5;
        cursorUpdated.y = event.clientY / window.innerHeight - 0.5;
        setCursor(cursorUpdated);
      });
    };

    const tearDown = () => {
      gsap.to("#canvas-es", {
        autoAlpha: 0,
        duration: 1,
        onComplete: () => {
          const element = document.getElementById("canvas-es");
          element.remove();
          const x = document.querySelector(".initiator");
          x.remove();
          loaded();
        },
      });
    };

    const runESanimation = () => {
      document.querySelector(".html-root").style.backgroundColor =
        "transparent";
      const canvas = document.getElementById("canvas-es");
      if (canvas) {
        const context = canvas.getContext("2d");
        const resolution = window.devicePixelRatio || 1;
        let vw = window.innerWidth;
        let vh = window.innerHeight;
        canvas.width = vw * resolution;
        canvas.height = vh * resolution;

        const frameCount = 121;
        const currentFrame = (index) =>
          `Assets/Images/es-data/ezgif-frame-${index + 1}.png`;

        const images = [];
        const Es = {
          frame: 0,
        };

        for (let i = 0; i < frameCount; i++) {
          const img = new Image();
          img.src = currentFrame(i);
          images.push(img);
        }

        gsap.to(Es, {
          frame: frameCount - 1,
          snap: "frame",
          ease: "none",
          duration: 5,
          onUpdate: render, // use animation onUpdate instead of scrollTrigger's onUpdate
          onComplete: tearDown,
        });

        images[0].onload = render;

        function render() {
          context.clearRect(0, 0, canvas.width, canvas.height);
          context.drawImage(images[Es.frame], 0, 0);
        }
      }
    };
    const launchApp = () => {
      if (isDesktop) {
        runESanimation();
      } else {
        gsap.to(".html-root", { backgroundColor: "rgb(0,0,0,0)", duration: 1 });
        loaded();
      }
    };
    gsap.to(".initiator-quote", { autoAlpha: 1, duration: 3, delay: 2 });
    gsap.to(".initiator-button", { autoAlpha: 1, duration: 2 }, ">");
    document
      .querySelector(".initiator-button")
      .addEventListener("click", () => {
        gsap.to(".initiator-button", { autoAlpha: 0, duration: 0.5 });
        gsap.to(
          ".initiator-quote",
          { autoAlpha: 0, duration: 1, delay: 0.5 },
          "<"
        );
        gsap.to(".image-gif", { autoAlpha: 0, duration: 1, delay: 1 }, "<");
        gsap.to(
          ".initiator",
          { autoAlpha: 0, duration: 1, delay: 0.5, onComplete: launchApp },
          "<"
        );
      });
  }, []);

  useEffect(() => {
    let textureChange = (color) => {
      gsap.to(".scroll-down", { opacity: 0, duration: 1 });
      setParticleColor(color.hex);
      if (isDesktop) {
        HandAnimation["CubeShake"].fadeIn(1).play();
        gsap.to(ParticleOneRef.current.position, {
          x: -6,
          y: 0.15,
          z: -30.2,
          duration: 0.2,
          onComplete: () => {
            let tl = gsap.timeline({
              repeat: 8,
              onComplete: () => {
                gsap.set(ParticleOneRef.current.position, {
                  x: -8,
                  y: 5,
                  z: -47,
                });
                HandAnimation["CubeShake"].fadeOut(1).stop();
                HandAnimation["CubeHover"].fadeIn(1).play();

                gsap.to(".scroll-down", { opacity: 1, duration: 1, delay: 1 });
              },
            });
            tl.to(ParticleOneRef.current.position, {
              x: -6.6,
              y: 0.15,
              z: -30.7,
              duration: 0.1,
            });
            tl.to(
              ParticleOneRef.current.position,
              { x: -6, y: 0.15, z: -30.7, duration: 0.1 },
              ">"
            );
            tl.to(
              ParticleOneRef.current.position,
              { x: -5.4, y: 0.15, z: -30.2, duration: 0.1 },
              ">"
            );
            tl.to(
              ParticleOneRef.current.position,
              { x: -6, y: 0.15, z: -30.2, duration: 0.1 },
              ">"
            );
          },
        });
        gsap.to(
          ParticleTwoRef.current.position,
          {
            x: -6,
            y: 0,
            z: -31.2,
            duration: 0.3,
            onComplete: () => {
              let tl = gsap.timeline({
                repeat: 8,
                onComplete: () => {
                  gsap.set(ParticleTwoRef.current.position, {
                    x: -4,
                    y: 3,
                    z: -50,
                  });
                },
              });
              tl.to(ParticleTwoRef.current.position, {
                x: -6,
                y: 0.6,
                z: -31.2,
                duration: 0.1,
              });
              tl.to(
                ParticleTwoRef.current.position,
                { x: -6, y: 0.6, z: -30.2, duration: 0.1 },
                ">"
              );
              tl.to(
                ParticleTwoRef.current.position,
                { x: -6, y: -0.2, z: -30.2, duration: 0.1 },
                ">"
              );
              tl.to(
                ParticleTwoRef.current.position,
                { x: -6, y: -0.2, z: -31.2, duration: 0.1 },
                ">"
              );
            },
          },
          "<"
        );
        gsap.to(
          ParticleThreeRef.current.position,
          {
            x: -5.6,
            y: -0.1,
            z: -30.2,
            duration: 0.4,
            onComplete: () => {
              let tl = gsap.timeline({
                repeat: 8,
                onComplete: () => {
                  gsap.set(ParticleThreeRef.current.position, {
                    x: -6,
                    y: 3,
                    z: -45,
                  });
                },
              });
              tl.to(ParticleThreeRef.current.position, {
                x: -6.4,
                y: 0.5,
                z: -30.2,
                duration: 0.1,
              });
              tl.to(
                ParticleThreeRef.current.position,
                { x: -6.4, y: 0.5, z: -31.2, duration: 0.1 },
                ">"
              );
              tl.to(
                ParticleThreeRef.current.position,
                { x: -5.6, y: -0.1, z: -31.2, duration: 0.1 },
                ">"
              );
              tl.to(
                ParticleThreeRef.current.position,
                { x: -5.6, y: -0.1, z: -30.2, duration: 0.1 },
                ">"
              );
            },
          },
          "<"
        );
        gsap.to(
          ParticleFourRef.current.position,
          {
            x: -5.6,
            y: 0.4,
            z: -30.2,
            duration: 0.5,
            onComplete: () => {
              let tl = gsap.timeline({
                repeat: 8,
                onComplete: () => {
                  gsap.set(ParticleFourRef.current.position, {
                    x: -5,
                    y: 3,
                    z: -50,
                  });
                },
              });
              tl.to(ParticleFourRef.current.position, {
                x: -6.4,
                y: -0.1,
                z: -30.2,
                duration: 0.1,
              });
              tl.to(
                ParticleFourRef.current.position,
                { x: -6.4, y: -0.1, z: -31.2, duration: 0.1 },
                ">"
              );
              tl.to(
                ParticleFourRef.current.position,
                { x: -5.6, y: 0.4, z: -31.2, duration: 0.1 },
                ">"
              );
              tl.to(
                ParticleFourRef.current.position,
                { x: -5.6, y: 0.4, z: -30.2, duration: 0.1 },
                ">"
              );
            },
          },
          "<"
        );
      }
      if (isDesktop) {
        HandModel.current.traverse((child) => {
          if (child.material && child.material.name === "Mat.1") {
            gsap.to(PointLightRef.current, {
              intensity: 0,
              duration: 1,
              onComplete: () => {
                gsap.set(PointLightRef.current.color, {
                  r: color.r,
                  g: color.g,
                  b: color.b,
                });
              },
            });
            gsap.to(
              child.material,
              {
                emissiveIntensity: 0,
                duration: 0.5,
                onComplete: () => {
                  gsap.set(child.material.emissive, {
                    r: color.r,
                    g: color.g,
                    b: color.b,
                  });
                },
              },
              ">"
            );
            gsap.to(
              PointLightRef.current,
              { intensity: 0.15, duration: 1 },
              ">"
            );
            gsap.to(
              PointLightRef.current.color,
              { r: 0.98, g: 0.98, b: 0.94, duration: 2 },
              ">"
            );
            gsap.to(
              child.material,
              {
                emissiveIntensity: color.i,
                duration: 2,
              },
              "<"
            );
          }
        });
      } else {
        HandModel.current.traverse((child) => {
          if (child.material && child.material.name === "Mat.003") {
            gsap.to(child.material.emissive, {
              r: color.r,
              g: color.g,
              b: color.b,
              duration: 2,
            });
          }
          if (child.material && child.material.name === "Material.001") {
            gsap.to(child.material.emissive, {
              r: color.r - 0.15,
              g: color.g - 0.15,
              b: color.b - 0.15,
              duration: 2,
            });
          }
        });
        gsap.to(".scroll-down", {
          opacity: 1,
          duration: 1,
          delay: 1,
        });
      }
    };
    switch (page) {
      case "A":
        let intensityA = isDesktop ? 1.4 : 3;
        setcursorMove(false);
        textureChange({ r: 0.23, g: 0.23, b: 0, i: intensityA, hex: 0xc9c900 });
        break;
      case "B":
        setcursorMove(false);
        textureChange({ r: 0, g: 0.76, b: 0.38, i: 1.2, hex: 0x00c261 });
        break;
      case "C":
        setcursorMove(false);
        textureChange({ r: 0, g: 1, b: 0, i: 0.9, hex: 0x00ff00 });
        break;
      case "D":
        let intensityD = isDesktop ? 1 : 3;
        setcursorMove(false);
        textureChange({ r: 0.27, g: 0.35, b: 0, i: intensityD, hex: 0xacf500 });
        break;
      case "E":
        setcursorMove(false);
        textureChange({ r: 0.12, g: 1, b: 0.11, i: 0.9, hex: 0xd0ff61 });
        break;
      case "F":
        setcursorMove(false);
        textureChange({ r: 1, g: 1, b: 1, i: 1, hex: 0xffffff });
        break;
      case "G":
        setcursorMove(false);
        textureChange({ r: 0, g: 1, b: 0, i: 0.9, hex: 0x00ff00 });
        break;
      default:
        break;
    }
  }, [page, setTagline, HandModel, HandAnimation]);

  useFrame((state, delta) => {
    if (isDesktop) {
      if (cursorMove) {
        state.camera.position.lerp(
          new Vector3(cursor.x * 2.68, -3.6, cursor.y * 0.86),
          0.02
        );
        state.camera.lookAt(-5, 0.8, -35.4);
      }
    }
  });

  return (
    <>
      <Leva hidden />
      <EnvironmentSettings PointLightRef={PointLightRef} />
      <Model
        url={HandModelUrl}
        position={{ x: -5.09, y: -1.8, z: -35.4 }}
        rotation={{ x: 0.4, y: -8.6, z: -0.03 }}
        scale={1}
        name="Hand"
        ref={HandModel}
        animation={true}
        animationControl={setHandAnimation}
      />
      {isDesktop && (
        <Sparkles
          position={[
            sparklesSettings.position.x,
            sparklesSettings.position.y,
            sparklesSettings.position.z,
          ]}
          count={36}
          scale={sparklesSettings.scale}
          size={sparklesSettings.size}
          speed={0.2}
          color={0x55ff5c}
          noise={2}
        />
      )}
      {/**center side */}
      <Particle
        ref={ParticleOneRef}
        color={ParticleColor}
        position={[-8, 5, -47]}
      />
      {/**center top */}
      <Particle
        ref={ParticleTwoRef}
        color={ParticleColor}
        position={[-4, 3, -50]}
      />
      {/**diagonal left */}
      <Particle
        ref={ParticleThreeRef}
        color={ParticleColor}
        position={[-6, 3, -45]}
      />
      {/**diagonal right */}
      <Particle
        ref={ParticleFourRef}
        color={ParticleColor}
        position={[-5, 3, -50]}
      />
    </>
  );
}
