import { useGLTF, useAnimations } from "@react-three/drei";
import { forwardRef, useEffect } from "react";
import { useControls } from "leva";
import { isDesktop } from "react-device-detect";
import * as THREE from "three";
const Model = forwardRef(function Model(
  {
    url,
    position,
    rotation,
    scale,
    name,
    Children,
    animation,
    hidden,
    animationControl,
  },
  ref
) {
  const model = useGLTF(url);
  const { actions } = useAnimations(model.animations, model.scene);
  let visible = true;
  if (hidden) {
    visible = false;
  }
  const modelSettings = useControls(name || "Model", {
    PosX: position.x,
    PosY: position.y,
    PosZ: position.z,
    RotX: rotation.x,
    RotY: rotation.y,
    RotZ: rotation.z,
  });

  useEffect(() => {
    if (animation) {
      if (isDesktop) {
        actions["CubeHover"].play();
      } else {
        let a2 = actions["Animation"];
        a2.setLoop(THREE.LoopRepeat);

        a2.play();
      }
      animationControl(actions);
    }
  }, [animation, actions, animationControl]);

  return (
    <group>
      <primitive
        object={model.scene}
        position={[modelSettings.PosX, modelSettings.PosY, modelSettings.PosZ]}
        rotation={[modelSettings.RotX, modelSettings.RotY, modelSettings.RotZ]}
        scale={scale}
        ref={ref}
        visible={visible}
      >
        {Children}
      </primitive>
    </group>
  );
});

export default Model;
