import { PerspectiveCamera } from "@react-three/drei";
import { useControls } from "leva";
import React from "react";
import { isMobile } from "react-device-detect";

export default function Camera() {
  let position = { x: 2.68, y: 9, z: 0.86 };
  if (isMobile) {
    position = { x: -2.2, y: -1.7, z: -16 };
  }
  const camera = useControls("Camera", {
    PosX: position.x,
    PosY: position.y,
    PosZ: position.z,
    RotX: 0.11,
    RotY: 0.25,
    RotZ: 0,
  });
  return (
    <PerspectiveCamera
      makeDefault
      position={[camera.PosX, camera.PosY, camera.PosZ]}
      rotation={[camera.RotX, camera.RotY, camera.RotZ]}
      fov={25}
    />
  );
}
