import React, { Suspense, useState } from "react";
import { Canvas } from "@react-three/fiber";
import Loader from "./Components/Loader";
import PageLayout from "./Pages/PageLayout";
import OnlyHTML from "./Components/OnlyHTML";
import Camera from "./Components/Camera";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { isDesktop } from "react-device-detect";
import "./App.css";
gsap.registerPlugin(ScrollTrigger);

function App() {
  const [tagline, setTagline] = useState(true);
  const [navigation, setNavigation] = useState(true);
  const [page, setPage] = useState("S");

  return (
    <>
      <Canvas className="canvas" shadows={true}>
        <Suspense fallback={<Loader />}>
          <PageLayout
            setTagline={setTagline}
            setNavigation={setNavigation}
            page={page}
          />
          <Camera />
        </Suspense>
      </Canvas>
      {isDesktop && <canvas id="canvas-es" className="html-full-es"></canvas>}
      <OnlyHTML tagline={tagline} navigation={navigation} setPage={setPage} />
    </>
  );
}

export default App;
