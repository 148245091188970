import {
  Bloom,
  EffectComposer,
  DepthOfField,
} from "@react-three/postprocessing";
import { Environment, Lightformer } from "@react-three/drei";
import { useControls } from "leva";
import { isDesktop } from "react-device-detect";

export default function EnvironmentSettings(props) {
  const AmbientlightSettings = useControls("Ambient Light", {
    PosX: 0.8,
    PosY: 4,
    PosZ: -8,
    scale: 5,
    intensity: 6,
    Color: "#fcfcf0",
  });

  const PointLightSettings = useControls("Point Light", {
    PosX: 0.8,
    PosY: 4,
    PosZ: -35,
    intensity: 0.15,
    Color: "#fcfcf0",
  });
  let effectComposer;
  if (isDesktop) {
    effectComposer = {
      multisampling: 4,
      bloomIntensity: 4,
      luminanceThreshold: 0.1,
      levels: 6,
      focusDistance: 0.6,
      focalLength: 1.8,
      bokehScale: 2,
      height: 480,
    };
  } else {
    effectComposer = {
      multisampling: 1,
      bloomIntensity: 1.8,
      luminanceThreshold: 0.2,
      levels: 6,
      focusDistance: 0.6,
      focalLength: 1.8,
      bokehScale: 1,
      height: 480,
    };
  }

  return (
    <>
      {isDesktop && (
        <Environment>
          <Lightformer
            form="circle"
            intensity={AmbientlightSettings.intensity}
            position={[
              AmbientlightSettings.PosX,
              AmbientlightSettings.PosY,
              AmbientlightSettings.PosZ,
            ]}
            color={AmbientlightSettings.Color}
            scale={AmbientlightSettings.scale}
          />
        </Environment>
      )}
      {isDesktop && (
        <pointLight
          ref={props.PointLightRef}
          intensity={PointLightSettings.intensity}
          position={[
            PointLightSettings.PosX,
            PointLightSettings.PosY,
            PointLightSettings.PosZ,
          ]}
          color={PointLightSettings.Color}
          decay={0}
        />
      )}
      <EffectComposer multisampling={effectComposer.multisampling}>
        <Bloom
          mipmapBlur
          intensity={effectComposer.bloomIntensity}
          luminanceThreshold={effectComposer.luminanceThreshold}
          levels={effectComposer.levels}
        />
        {isDesktop && (
          <DepthOfField
            focusDistance={effectComposer.focusDistance}
            focalLength={effectComposer.focalLength}
            bokehScale={effectComposer.bokehScale}
            height={effectComposer.height}
          />
        )}
      </EffectComposer>
    </>
  );
}
